<template>
  <div id="app">
    <img alt="Vue logo" id="logo" src="./assets/logo.png" />
    <h1>Appeel.io Challenge JS</h1>
    <Grid :grid="grid" :rndX="randomX" :rndY="randomY" />
    <div
      id="hiddensecret"
      :data-attribute-x="randomX"
      :data-attribute-y="randomY"
    />
  </div>
</template>

<script>
import Grid from "./components/Grid";
export default {
  name: "App",
  components: {
    Grid,
  },
  data() {
    return {
      grid: {
        rows: 6,
        cols: 26,
      },
    };
  },
  computed: {
    randomX() {
      return Math.floor(Math.random() * this.grid.cols + 1);
    },
    randomY() {
      return Math.floor(Math.random() * this.grid.rows + 1);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  margin: 2rem 0;
}
</style>
