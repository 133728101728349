<template>
  <div :style="gridClass">
    <div
      v-for="n in grid.rows * grid.cols"
      class="circle-outline"
      @mouseover="hover(n)"
      :key="n"
      :data-attribute-secret="isAttributePlace(n) ? '#hiddensecret' : null"
    >
      <button v-if="isButtonPlace(n) && isFound()">GEVONDEN!</button>
      <span class="circle" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Grid",
  props: ["grid", "rndX", "rndY"],
  data() {
    return {
      found: false,
    };
  },
  computed: {
    randomAttributePlacement() {
      return Math.floor(Math.random() * (this.grid.rows * this.grid.cols) + 1);
    },
    gridClass() {
      return {
        display: "grid",
        "grid-template-columns": `repeat(${this.grid.cols}, 25px)`,
        "grid-template-rows": `repeat(${this.grid.rows}, 25px)`,
      };
    },
  },
  methods: {
    hover(n) {
      const found = this.isButtonPlace(n);
      if (found) this.found = true;
    },
    isFound() {
      return this.found;
    },
    isAttributePlace(n) {
      return n === this.randomAttributePlacement;
    },
    isButtonPlace(n) {
      const x = n % this.grid.cols || this.grid.cols;
      const y = Math.ceil(n / this.grid.cols);
      return x === this.rndX && y === this.rndY;
    },
  },
};
</script>

<style scoped>
.circle-outline {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circle {
  display: block;
  background-color: #5ac7f2;
  width: 1px;
  height: 1px;
  padding: 1px;
  border-radius: 5px;
}

button {
  position: absolute;
  background: #5ac7f2;
  color: white;
  border: 0;
}
</style>
